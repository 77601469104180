@import '../../styles/customMediaQueries.css';

.align {
    text-align: left;
    justify-self: start;

    @media (--viewportMedium) {
        text-align: center;
        justify-self: center;
    }
}

.title {
    composes: align;
    max-width: 30ch;
}

.mainList {
    text-align: justify;
    padding: 0;
    list-style-position: inside;

    ol {
        padding-left: 20px;
    }

    li::marker {
        font-weight: bolder;
    }
}

.nestedList1>li::marker {
    font-family: "Open sans";
    font-size: 16px;
    content: "1." counter(list-item) " ";
}

.nestedList3>li::marker {
    font-family: "Open sans";
    font-size: 16px;
    content: "3." counter(list-item) " ";
}

.nestedList3insideList1>li::marker {
    font-family: "Open sans";
    font-size: 16px;
    content: "3.1." counter(list-item) " ";
}

.nestedList3insideList6>li::marker {
    font-family: "Open sans";
    font-size: 16px;
    content: "3.6." counter(list-item) " ";
}

.nestedList4>li::marker {
    font-family: "Open sans";
    font-size: 16px;
    content: "4." counter(list-item) " ";
}

.nestedList6>li::marker {
    font-family: "Open sans";
    font-size: 16px;
    content: "6." counter(list-item) " ";
}

.nestedList7>li::marker {
    font-family: "Open sans";
    font-size: 16px;
    content: "7." counter(list-item) " ";
}