.root {
    display: flex;
    flex-direction: column;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    gap: 4px;
    align-items: center;
    margin-bottom: 4px;
}

.info {
    composes: h4 from global;
    color: var(--colorFail);
    overflow: hidden;
    font-size: 13px;
    margin: 0;
}

.infoSuccess {
    composes: info;
    color: #2BAC47
}

.icon {
    width: 17px;
}

.barsContainer {
    display: flex;
    flex-direction: row;
    gap: 2px;
}


.veryWeak {
    width: 25%;
    height: 4px;
    background-color: #C83532;
}

.weak {
    width: 25%;
    height: 4px;
    background-color: orange;
}

.good {
    width: 25%;
    height: 4px;
    background-color: #00AB59;
}

.veryGood {
    width: 25%;
    height: 4px;
    background-color: #00944d;
}

.none {
    width: 25%;
    height: 4px;
    background-color: #D9D9D9;
}

.veryWeakText {
    color: #C83532;
    text-align: right;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
}

.weakText {
    color: orange;
    text-align: right;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
}

.goodText {
    color: #00AB59;
    text-align: right;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
}

.veryGoodText {
    color: #00944d;
    text-align: right;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
}