.label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0px;
}

.inputContainer {
    composes: input from global;
    height: 48px;
    padding: 0px 16px 7px 16px;

    &:focus-within {
        background-color: #fff;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        transition: all ease-in-out 100ms;
    }
}

.inputContainer input {
    border: unset;

    &:focus {
        border: unset;
        box-shadow: unset;
    }
}