@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  align-items: center;
  justify-content: start;
  gap: 10px;
  &>p{
    line-height: 31px;
    font-size: 20px;
    font-weight: 600;
    color: var(--marketplaceColor);
    font-family: 'Montserrat';
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.headerCentered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.headerLeft {}

.horizontalWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  @media (--viewportSm){
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
}

.optionWrapper {
  composes: horizontalWrapper;
  border: 1px solid var(--marketplaceColor);
  border-radius: 16px;
  padding: 30px;
  gap: 26px;

  &:hover p {
    cursor: pointer;
    background-color: var(--marketplaceColor);
    color: white;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--marketplaceColor);
  }
}

.optionIcon {
  width: 140px;
  height: 140px;
}

.optionInfo {
  line-height: 28px;
  color: var(--marketplaceColor);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  letter-spacing: 0.70px;
  @media (--viewportSm){
    margin-bottom: 0;
  }
  /* &:hover {
    cursor: pointer;
    color: white;
  } */
}

.info {
  color: #D2A82C;
  font-size: 13px;
  text-align: center;
  padding: 0px;
}

.icon {
  width: 20px;
  height: 20px;
}