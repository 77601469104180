@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  gap: 16px;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.input {
  margin-top: 24px;
}

.firstNameRoot {
  width: 100%;
}

.lastNameRoot {
  width: 100%;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  justify-content: center;
  margin-top: 12px;
}

.info {
  composes: marketplaceModalHelperLink from global;
  text-align: left;
  padding: 0;
}

.link {
  composes: marketplaceModalHelperLink from global;
  padding: 0;
  color: var(--marketplaceSecondaryColor);
  text-decoration: underline;

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}

.line {
  height: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  background-color: var(--marketplaceColor);
}
