.root {
}

.inputError {
  border: var(--borderErrorField);
}

.passwordContainer {
  display: flex;
}

.passwordContainer > input {
  min-width: 100%;
}
